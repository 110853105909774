import React, { useState }  from 'react';
import Api from '../common/Api';
import qs from 'qs';

export default function Register(props) {

    const [input, setInput] = useState({
        userName: "",
        password: "",
        confirm: "",
        fname: "",
        lname: ""
    });

    const [warn, setWarn] = useState("");

    function onChange(e) {
        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    function registerUser() {

        debugger;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        const data = qs.stringify({
            grant_type: "password",
            Email: input.userName, 
            Password: input.password,
            ConfirmPassword: input.confirm,
            FirstName: input.fname,
            LastName: input.lname
        });

        //{ headers: headers }
        Api.post("/api/account/register", data)
            .then((resp) => {

                // FIXME... put here for now... gonna see if we use cookies and not have to stash manually. 
                //sessionStorage.setItem("token", resp.data.access_token);
                alert("Congratulations, you are now a user loser!");

                // Go Home
                props.history.push("/");

            })
            .catch(() => { setWarn("Registration failed. Did you provided all the required fields?"); });

    }

    return (
        <div className="container">
            <div className="card mt-2 p-3">
                <h2>Register</h2>
                <p>
                    Please register by providing your email and a unique password. A verification email will
                    be sent to complete the registration process.
                </p>

                     
                <form>
                    <div className="input-group mb-3">
                        <label htmlFor="fname">First Name</label>
                        <input type="text" name="fname" className="form-control ml-3 mr-3" placeholder="First Name" onChange={onChange} />
                        <label htmlFor="lname">Last Name</label>
                        <input type="text" name="lname" className="form-control ml-3" placeholder="Last Name" onChange={onChange} />
                    </div>
                    

                    <div className="form-group">
                        <label htmlFor="userName">Email</label>
                        <input className="form-control" type="email" name="userName" value={input.userName} onChange={onChange} placeholder="User Name" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input className="form-control password" type="password" name="password" onChange={onChange} placeholder="Password" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Confirm Password</label>
                        <input className="form-control password" type="password" name="confirm" onChange={onChange} placeholder="Confirm Password" />
                    </div>

                    {warn === "" ? null :
                        <p className="red" >{warn} </p>
                    }

                </form>
                <button className="btn btn-primary" onClick={registerUser} >Register</button>


            </div> 

        </div>
    );

}