import React, { useState } from 'react';

export default function DuitAbout(props) {

    const [showAbout, setShowAbout] = useState(false);

    // overcome main.css 100% on all inputs :-( 
    const autoWidth = {
        width: "auto",
        display: "inline"
    };

    function onDontShowAgainChange(src) {
        // preserve user option
        localStorage.setItem("duit_about_dontshowagain", src.target.checked);        
    }


    return (
        <div className="container">
            <div className="card mt-2 p-3 text-left">

                <h2>Duit Task Manager</h2>
                <p>
                    Duit (Do It!) is simple TODO app
                </p>

                <p>
                    This handy task management app has all these cool features...
                    </p>
                <ul>

                    <li>&bull; Daily, Weekly, and Custom Views</li>
                    <li>&bull; Recurring Tasks</li>
                    <li>&bull; Tags</li>
                    <li>&bull; Contexts</li>
                </ul>
                  
                
                {!showAbout ? <a href="#duit" onClick={() => setShowAbout(true)}>Read More..</a> :
                    (<div>
                        <h4>
                            Why write another task app?
                        </h4>
                        <p>
                            There was no *need* to do so, it's been done a million times before... but here is why I decided to make my own.
                        <br />
                        I've used a handful of GTD (Getting things done) apps over the years. One of the first ones I used was Things for IOS,
                        then OmniFocus, and finally 2Day For Windows. They all had their pros and cons, with 2Day being my favorite. Then one day
                        Microsoft hired the guy who wrote 2Day to work on their own Task App... and suddenly 2Day was no longer supported.
                        <br /><br />
                        I could use one of the online services, but I wanted it to work in a particular way.
                        </p>
                    </div>)

                }

                <span><input type="checkbox" value="" style={autoWidth} onChange={onDontShowAgainChange} className="pull-left" />  Don't show me this welcome page again!</span>
                
                <div>
                    <button className="btn btn-primary" onClick={() => { props.setShowAbout(false) }} >Launch</button>
                </div>

            </div>
        </div>
    );

};



