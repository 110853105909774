import * as UA from './userActions';

export function userReducer(
    state = {
        loggedIn: false,
        accessToken: "",
        firstName: "Charlie"
    }, action) {
    switch (action.type) {

        case UA.USER_LOGGED_IN:
            return {
                ...state, 
                loggedIn: action.val
            };

        case UA.USER_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.val
            };

        default:
            return {
                ...state
            };
    }

}