import React, { useState } from 'react';

export default function PlanetAbout(props) {

    const [showAbout, setShowAbout] = useState(false);

    // overcome main.css 100% on all inputs :-( 
    const autoWidth = {
        width: "auto",
        display: "inline"
    };

    function onDontShowAgainChange(src) {
        // preserve user option
        localStorage.setItem("planet_about_dontshowagain", src.target.checked);        
    }


    return (
        <div className="container">
            <div className="card mt-2 p-3 text-left">

                <h2>Planet Event Coordinator</h2>
                <p>
                    Planet (Plan It!) is simple app for coordinating events with multiple people 
                </p>
                  
                <span><input type="checkbox" value="" style={autoWidth} onChange={onDontShowAgainChange} className="pull-left" />  Don't show me this welcome page again!</span>
                
                <div>
                    <button className="btn btn-primary" onClick={() => { props.setShowAbout(false) }} >Launch</button>
                </div>

            </div>
        </div>
    );

};



