import React, { useState } from 'react';
import Api from '../common/Api';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import * as UA from '../common/store/userActions';

export default function Signin(props) {

    const [input, setInput] = useState({
        userName: "",
        password: "",
        warn: "xxx"
    });

    const [warn, setWarn] = useState("");

    const dispatch = useDispatch();


    function onChange(e) {
        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    function signIn() {

        /*const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };*/

        const data = qs.stringify({
            grant_type: "password",
            userName: input.userName !== "" ? input.userName : "fishlet@yahoo.com",
            password: input.password !== "" ? input.password : "he#kG8gjGh"
        });

        //{ headers: headers }
        Api.post("/TOKEN", data )
            .then((resp) => {

                // FIXME... put here for now... gonna see if we use cookies and not have to stash manually. 
                dispatch({ type: UA.USER_ACCESS_TOKEN, val: resp.data.access_token });
                dispatch({ type: UA.USER_LOGGED_IN, val: true });

                // Go Home
                props.history.push("/");

            })
            .catch(() => { setWarn("Email or Password is incorrect."); });
            
    }

    return (
        <div>
            <div className="container">

                <div className='card mt-2 p-3'>

                    <h2>Sign In</h2>
                    <p>
                        Some site features require an acount. Please login below or <a href="#register">create an account.</a>
                    </p>
                    <form>
                        <div className="form-group">
                            <label htmlFor="userName">Email</label>
                            <input className="form-control" type="email" name="userName" value={input.userName} onChange={onChange} placeholder="User Name" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input className="form-control password" type="password" name="password" onChange={onChange} placeholder="Password" />
                        </div>

                        {warn === "" ? null :
                            <p className="red" >{warn} </p>
                        }

                    </form>
                    <button className="btn btn-primary" onClick={signIn} >Sign In</button>
                    {/*  onClick={() => signIn(props)}   */}

                </div>

            </div>

        </div>
    );
}



