import React, { useState, useEffect } from 'react';
import DuitAbout from './PlanetAbout';
import SideNav from '../../common/SideNav';

export default function PlanetHome(props) {

    var deps = [];

    const [showAbout, setShowAbout] = useState(true);

    useEffect(() => {
        console.log("init planethome");

        var dsa = localStorage.getItem("planet_about_dontshowagain");
        if (dsa === "true") setShowAbout(false); // dont show again chosen... hide 

    }, deps);

    // show welcome screen first. 
    if (showAbout) {
        return <DuitAbout setShowAbout={setShowAbout} />;
    }


    var NavContent = (
        <div>
            <button className="btn btn-info">Boo!</button>
        </div>
    );



    return (
        <div>

            <SideNav content={NavContent} /> 

            <div className="main">
                
                <div className="card mt-2 p-3 text-left">

                <h2>Planet Event Coordinator</h2>

                <p>This is the APP!</p> 
            </div>
        </div>

        </div>

    );

};



