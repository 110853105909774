import React, { useEffect } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';

export default function Header(props) {

    var mint = [{ count: 0 }];

    useEffect(() => {

        // Add drop down function handlers to menus
        $('.dropdown, .dropdown-submenu').addClass('hover');
        $(document).on({
            mouseenter: function () {
                $('.open').removeClass('open');
                $(this).addClass('open').find('.dropdown-toggle').removeAttr('data-toggle');
            },
            mouseleave: function () {
                $(this).removeClass('open').find('.dropdown-toggle').attr('data-toggle', 'dropdown');
            }
        }, '.dropdown.hover');
        

    },mint);


    const user = useSelector(s => s.user);
    //alert(user.firstName);


    return (
        <div>
            <header>
                <div className="navbar">
                    <div className="yamm">
                        <div className="navbar-collapse collapse">
                            <div className="container">

                                <div className="logoCrop">
                                    <a className="navbar-brand" href="#"><img src="mp.svg" className="logo" alt="" /></a>
                                </div>

                                <ul className="nav navbar-nav">

                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">Home</a>
                                    </li>

                                    <li className="dropdown">

                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">About</a>
                                      
                                        <ul className="dropdown-menu">
                                            <li><a href="#about">About Me</a></li>
                                            <li><a href="#aboutsite">About This Site</a></li>
                                        </ul> 
                                    </li>

                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">Apps</a>

                                        <ul className="dropdown-menu">
                                            <li><a href="#duit">Duit</a></li>
                                            <li><a href="#planet">Planet</a></li>
                                            <li><a href="https://mostpowerful.net/wtt" target="_new">WTT</a></li>
                                        </ul>
                                    </li>

                                    <li className="dropdown searchbox">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown"><i className="icon-search"></i></a>

                                        <div className="dropdown-menu dropdown-menu-right">
                                            <form id="search" className="navbar-form search" role="search">
                                                <input type="search" className="form-control" placeholder="Type to search" />
                                                <button type="submit" className="btn btn-submit icon-right-open"></button>
                                            </form>
                                        </div>
                                    </li>


                                </ul>

                                {!user.loggedIn ? 
                                    <a href="#/signin"><button className="btn btn-primary pull-right">Sign in</button></a> :
                                    <p>Hi { user.firstName} </p>

                                    }
                                

                            </div>

                        </div>

                    </div>
                </div>
            </header>
        </div>
    );

}
