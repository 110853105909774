import React from 'react';

export default function Home(props) {

    return (
        <div className="container">
            <div className='card mt-2 p-3'>

                <h2>Welcome To MostPowerful.Net!!!</h2> 
                <p>
                    Personal & Professional web site of <b>Chris Roberts</b>. 
                </p>
                <p>
                    Please enjoy my blog below, or navigate the menus to a check out some of my projects, resume, and other interesting stuff. Some features may require an account. 
                </p>
            </div> 

            
        </div>
    );

}