import { createStore, combineReducers, applyMiddleware } from "redux";
import { userReducer } from './userReducer';

//import thunk from "redux-thunk";

export const rootReducer = combineReducers({
    user: userReducer,
});


/*-------------------------------------------------------------------------------------------------------------------
 * Code stolen from the following, with local storage changed to session storage. 
 * https://medium.com/swlh/persisting-data-with-redux-thunk-session-storage-in-react-application-9cc94549a1c
 *-----------------------------------------------------------------------------------------------------------------*/
// convert object to string and store in localStorage
function saveToLocalStorage(state) {
    try {
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem("persistantState", serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage() {
    try {
        const serialisedState = sessionStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}
/*-------------------------------------------------------------------------------------------------------------------*/


const store = createStore(
    rootReducer, loadFromLocalStorage()
);

store.subscribe(() => saveToLocalStorage(store.getState()));


export default store;

