import axios from 'axios';


// if (process.env.NODE_ENV === "development")

// dev... use local machine MP.net domain pointing to svcs
// production... use live site endpoints
export default axios.create({
    baseURL: process.env.NODE_ENV === "development" ?  'http://mp.net/svcs/' : 'https://mostpowerful.net/svcs/' 
});


