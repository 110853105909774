import React from 'react';


export default function AboutSite(props) {
    return (
        <div className="container">
            <div className="card mt-2 p-3">
                <h2>About This Site</h2>

                <p>
                    This is the Personal & Professional web site of <b>Chris Roberts</b>.
                </p>
                <h4>Privacy Policy & Cookies</h4>
                <p>
                    This site uses cookies for functional purposes only. No personal information is collected, or shared with third parties. This site does
                    not host advertising, and has no ad networks or trackers. Minimal user information, such as an email address is collected for use with 
                    some applications on this site, however this information will not be sold or shared with any party without your consent. 
                </p> 
                <br />
                <h4>
                    Why is this site called "Most Powerful.net"
                </h4>
                <p>
                    "There are only two hard things in Computer Science: cache invalidation, naming things, and off-by-one errors". Well, naming things 
                    IS hard.. when programming and when trying to build a website. True; this site isn't particularly powerful. It will not solve world 
                    hunger or make all your dreams come true. But the name was memorable enough, and more importantly... not already taken. If a really
                    good name comes along, and it's not on some some bizarre TLD, maybe I'll change it.
                </p>

                {/* <h3>Credits</h3> */}

                

            </div>
        </div>
    );

};



