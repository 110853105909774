import React from 'react';


export default function About(props) {
    return (
        <div className="container">
            <div className="card mt-2 p-3">
                About Me?
            </div>
        </div>
    );

};



