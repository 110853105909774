import React from 'react';

// Generic Sidebar... content provided by container
export default function SideNav(props) {

    return (
        <div className="sidenav">
            <br />
            <br />           
            {props.content}
        </div>
    );

}

