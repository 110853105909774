import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import './App.css';
import store from "./common/store/configureStore";
import Header from './views/Header';
import { HashRouter, Route, Switch } from "react-router-dom";
import About from './views/About';
import AboutSite from './views/AboutSite';
import Contact from './views/Contact';
import Signin from './views/Signin';
import Home from './views/Home';
import Register from './views/Register';
import DuitHome from './apps/Duit/DuitHome';
import PlanetHome from './apps/Planet/PlanetHome';

import './Layout.css';


function App() {

    useEffect(() => {
        let rs = store.getState();
    });


    return (
        <Provider store={store}>
            <div className="App">

                {/* Universal header... will show regardless of page or app running.  */}
                <Header />

                <HashRouter>

                    {/*<img src="img/misc/pooch.jpg" className="App-logo" alt="logo" /> */}
                    <div className="text-left">
                        <Switch>
                            <Route path="/about" exact={true} component={About} />
                            <Route path="/aboutsite" component={AboutSite} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/signin" component={Signin} />
                            <Route path="/" exact={true} component={Home} />
                            <Route path="/register" component={Register} />
                            <Route path="/duit" component={DuitHome} />
                            <Route path="/planet" component={PlanetHome} />

                        </Switch>

                    </div>

                </HashRouter>


            </div>
        </Provider>
    );

}


export default App;
